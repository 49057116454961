<template>
  <div class="questions authoring-wrapper">
    <div class="flex">
      <ProgressBar :stepIndex="4" :surveyID="surveyID" stepsType="pack" />
      <section class="wrapper">
        <a-breadcrumb class="breadcrum breadcrum--authoring">
          <template v-if="user !== null && user.SkinIdentifier !== 'pg'">
            <a-breadcrumb-item
              ><a href="/">{{ client.ClientName }}</a></a-breadcrumb-item
            >
            <a-breadcrumb-item
              ><a href="/products">Products</a></a-breadcrumb-item
            >
            <a-breadcrumb-item>Pack Test</a-breadcrumb-item>
          </template>
        </a-breadcrumb>
        <div class="wrapper__inner">
          <div class="page-title page-title--transparent-svg-color">
            <icon-base
              :width="32"
              :height="33"
              :viewBox1="24"
              :viewBox2="24"
              iconName="question"
              iconStroke="#4318FF"
              ><icon-question
            /></icon-base>
            <div class="page-title__text">
              <h1>Custom Questions</h1>
              <span>Add custom questions for your study</span>
            </div>
          </div>
          <div class="box-wrapper">
            <div class="questions__intro">
              <div class="section-info">
                <p v-html="langObj['s4-cQues'].quiz"></p>
              </div>
              <a-button
                type="primary"
                @click="
                  dlbStatus
                    ? (addCustomQuestionModal = false)
                    : (addCustomQuestionModal = true)
                "
                :class="{ dlb: dlbStatus }"
                :disabled="loading"
              >
                Add question
              </a-button>
            </div>

            <h2>Questions preview</h2>
            <div class="questions__box">
              <QuestionsListPreview
                v-if="
                  customQuestionsList !== null &&
                  customQuestionsList.CustomQuestions.length
                "
                :questionsList="customQuestionsList.CustomQuestions"
                :langObj="langObj"
                :questionSettings="true"
                :key="Math.random()"
                :loading="questionsConfig.loadingNewQuestions"
                :showSetAsScreener="false"
                :showSaveInLibrary="true"
                :hideAddButton="true"
                @deleteCustomQuestions="deleteCustomQuestions"
                @editQuestion="editQuestion"
                @saveInLibrary="saveInLibrary"
              ></QuestionsListPreview>
              <p v-else class="font-size-14 mb-0">
                <Loader
                  text="Loading custom question..."
                  v-if="loading"
                ></Loader>
                <span v-else>You don't have any custom questions yet.</span>
              </p>
            </div>

            <div
              v-if="
                customQuestionsList !== null &&
                customQuestionsList.CustomQuestions.length &&
                !tempHide
              "
            >
              <h2>Instructions</h2>
              <div class="questions__box">
                <label>
                  Do you have any requirements for the custom question(s)?
                  Please describe any logic, termination, dependencies or piping
                  that may be required.
                </label>
                <a-textarea
                  v-model="customQuestionsList.questionsInstructions"
                  @blur="insertCustomQuestions(customQuestionsList)"
                  :auto-size="{ minRows: 4 }"
                />
              </div>
            </div>
          </div>
          <StepNaviBar
            :stepIndex="4"
            :conciergeAssistance="true"
            @nextHandler="nextStep"
            :prevDisable="false"
            @prevHandler="prevStep"
            prevBtnTooltip="Back to Brand Strategy"
            nextBtnTooltip="Go to Launch"
            stepsType="pack"
          />
        </div>
      </section>
    </div>

    <a-modal
      :title="questionsModalTitle"
      :visible="addCustomQuestionModal"
      :width="questionsConfig.editMode ? 650 : 800"
      okText="Save"
      :okButtonProps="{
        props: { disabled: !questionsData.CustomQuestions.length },
      }"
      :footer="null"
      @cancel="closeQuestionBuilder('cancel')"
      :destroyOnClose="true"
      wrapClassName="ant-modal--custom-questions"
    >
      <div class="choose-questions-nav" v-if="!questionsConfig.editMode">
        <a-button
          @click="
            (questionsConfig.activeMode = 'builder'),
              (questionsConfig.activeBuilder = questionsConfig.type)
          "
          :type="questionsConfig.activeMode === 'builder' ? 'primary' : ''"
          v-html="langObj['s4-customQuestions'].modalTab1"
        ></a-button>
        <a-button
          @click="
            (questionsConfig.activeMode = 'examples'),
              (questionsConfig.activeBuilder = null)
          "
          :type="questionsConfig.activeMode === 'examples' ? 'primary' : ''"
          v-html="langObj['s4-customQuestions'].modalTab2"
        ></a-button>
        <a-button
          @click="
            (questionsConfig.activeMode = 'saved'),
              (questionsConfig.activeBuilder = null),
              getSavedQuestions()
          "
          :type="questionsConfig.activeMode === 'saved' ? 'primary' : ''"
          >Saved</a-button
        >
      </div>
      <div
        class="choose-questions"
        :class="{ 'choose-questions--edit': questionsConfig.editMode }"
      >
        <div
          class="choose-questions__types"
          v-if="
            !questionsConfig.editMode && questionsConfig.activeMode !== 'saved'
          "
        >
          <a-radio-group
            v-model="questionsConfig.type"
            disable
            :class="{
              'ant-radio-group--border-b': questionsData.CustomQuestions.length,
              'ant-radio-group--disabled':
                questionsConfig.previewQuestionsList ||
                questionsConfig.editMode,
            }"
          >
            <a-radio
              :style="questionsConfig.radioBtnTypesStyle"
              selected
              value="text"
            >
              <span
                v-html="langObj['s4-customQuestions'].modalQuestionType1"
              ></span>
            </a-radio>
            <a-radio
              :style="questionsConfig.radioBtnTypesStyle"
              selected
              value="essay"
            >
              <span
                v-html="langObj['s4-customQuestions'].modalQuestionType6"
              ></span>
            </a-radio>
            <a-radio :style="questionsConfig.radioBtnTypesStyle" value="radio">
              <span
                v-html="langObj['s4-customQuestions'].modalQuestionType2"
              ></span>
            </a-radio>
            <a-radio
              :style="questionsConfig.radioBtnTypesStyle"
              value="checkbox"
            >
              <span
                v-html="langObj['s4-customQuestions'].modalQuestionType3"
              ></span>
            </a-radio>
            <a-radio
              :style="questionsConfig.radioBtnTypesStyle"
              value="rank-dragdrop"
            >
              <span
                v-html="langObj['s4-customQuestions'].modalQuestionType4"
              ></span>
            </a-radio>
            <a-radio
              :style="questionsConfig.radioBtnTypesStyle"
              value="table-radio"
            >
              <span
                v-html="langObj['s4-customQuestions'].modalQuestionType7"
              ></span>
            </a-radio>
            <a-radio
              :style="questionsConfig.radioBtnTypesStyle"
              value="table-checkbox"
            >
              <span
                v-html="langObj['s4-customQuestions'].modalQuestionType5"
              ></span>
            </a-radio>
          </a-radio-group>
        </div>
        <div
          v-if="questionsConfig.activeMode === 'saved'"
          class="choose-questions__preview choose-questions__preview--saved"
          :class="{
            'flex justify-center items-center': savedQuestions.length === 0,
          }"
        >
          <Loader
            text="Loading questions..."
            v-if="loadingSavedQuestions"
            :paddingTop="151"
            :paddingBottom="151"
          ></Loader>
          <div v-else-if="savedQuestions.length">
            <div class="flex justify-end gap-15 mb-25 mt-10">
              <div class="input-group input-group--small">
                <div
                  class="position-r search-field"
                  :class="{ focus: searchActive }"
                >
                  <a-input
                    placeholder="Search by Question Text"
                    style="width: 230px"
                    v-model="searchText"
                    class="filterPanel__search"
                    @focus="searchActive = true"
                    @blur="searchActive = false"
                    :class="{
                      'filterPanel__search--active': searchText != '',
                    }"
                  >
                    <a-icon
                      slot="addonAfter"
                      theme="filled"
                      type="close-circle"
                      v-show="searchText != ''"
                      @click="searchText = ''"
                  /></a-input>
                  <icon-base
                    class="search-icon"
                    :width="20"
                    :height="20"
                    :viewBox1="64"
                    :viewBox2="64"
                    iconName="searchThin"
                    iconColor="transparent"
                    iconStroke="#000"
                    ><icon-search-thin
                  /></icon-base>
                </div>
              </div>
              <div class="input-group input-group--small">
                <a-select
                  v-model="categoryFilter"
                  :options="uniqueQuestionsCategories"
                  style="width: 230px"
                  mode="multiple"
                  placeholder="Filter by Category"
                  :filterOption="false"
                  :disabled="uniqueQuestionsCategories.length <= 1"
                >
                </a-select>
              </div>
            </div>
            <QuestionsListPreview
              v-if="filteredQuestions.length"
              :questionsList="filteredQuestions"
              :langObj="langObj"
              :questionSettings="true"
              :showSetAsScreener="false"
              :showSaveInLibrary="false"
              :hideEditButton="true"
              :insertCustomQuestionsLoading="insertCustomQuestionsLoading"
              @addSavedQuestion="addSavedQuestion($event)"
              @deleteCustomQuestions="deleteSavedQuestions"
            ></QuestionsListPreview>
            <div
              v-else
              class="
                flex
                items-center
                justify-center
                flex-col flex-1
                text-center
              "
              style="margin-top: 110px"
            >
              <icon-base
                :width="40"
                :height="40"
                :viewBox1="32"
                :viewBox2="32"
                iconName="no-results"
                iconColor="#415262"
                iconStroke="#fff"
                class="mb-10"
                ><icon-no-results
              /></icon-base>
              <p class="mb-20">
                No questions found. <br />
                Please adjust your search or filter criteria.
              </p>
            </div>
          </div>
          <div
            v-else
            class="flex items-center justify-center flex-col flex-1 text-center"
          >
            <icon-base
              :width="40"
              :height="40"
              :viewBox1="32"
              :viewBox2="32"
              iconName="no-results"
              iconColor="#415262"
              iconStroke="#fff"
              class="mb-10"
              ><icon-no-results
            /></icon-base>
            <p class="mb-20">No saved questions found.</p>
          </div>
        </div>
        <div v-else class="choose-questions__preview">
          <template>
            <QuestionsTypePreview
              v-if="questionsConfig.type === 'text'"
              :title="langObj['s4-customQuestions'].modalQuestionType1"
              :subTitle="langObj['s4-customQuestions'].modalTextQIntro"
              :exampleQuestionText="
                langObj['s4-customQuestions'].modalTextQExampleLabel
              "
              :description="
                langObj['s4-customQuestions'].modalTextQDetailsLabel
              "
              :builderMode="
                questionsConfig.type === 'text' &&
                questionsConfig.activeBuilder === 'text'
                  ? true
                  : false
              "
              :editMode="questionsConfig.editMode"
              :langObj="langObj"
            >
              <TextTypeBuilder
                v-if="
                  questionsConfig.type === 'text' &&
                  questionsConfig.activeBuilder === 'text'
                "
                @question-data="setQuestionData"
                @question-builde:close="closeQuestionBuilder"
                :editMode="questionsConfig.editMode"
                :questionInfo="customQuestionsList"
                :questionIndex="questionsConfig.editModeItemIndex"
                :langObj="langObj"
              ></TextTypeBuilder>
              <TextTypeExample
                :exampleQuestionText="
                  langObj['s4-customQuestions'].modalTextQExampleLabel
                "
                :langObj="langObj"
                :questionSettings="false"
                v-else
              ></TextTypeExample>
            </QuestionsTypePreview>
            <QuestionsTypePreview
              v-if="questionsConfig.type === 'essay'"
              :title="langObj['s4-customQuestions'].modalQuestionType6"
              :subTitle="langObj['s4-customQuestions'].modalEssayQIntro"
              :exampleQuestionText="
                langObj['s4-customQuestions'].modalEssayQExampleLabel
              "
              :description="
                langObj['s4-customQuestions'].modalEssayQDetailsLabel
              "
              :builderMode="
                questionsConfig.type === 'essay' &&
                questionsConfig.activeBuilder === 'essay'
                  ? true
                  : false
              "
              :editMode="questionsConfig.editMode"
              :langObj="langObj"
            >
              <TextTypeBuilder
                v-if="
                  questionsConfig.type === 'essay' &&
                  questionsConfig.activeBuilder === 'essay'
                "
                @question-data="setQuestionData"
                @question-builde:close="closeQuestionBuilder"
                :editMode="questionsConfig.editMode"
                :questionInfo="customQuestionsList"
                :questionIndex="questionsConfig.editModeItemIndex"
                :langObj="langObj"
                type="essay"
              ></TextTypeBuilder>
              <TextTypeExample
                :exampleQuestionText="
                  langObj['s4-customQuestions'].modalEssayQExampleLabel
                "
                :langObj="langObj"
                :questionSettings="false"
                type="essay"
                v-else
              ></TextTypeExample>
            </QuestionsTypePreview>
            <QuestionsTypePreview
              v-if="questionsConfig.type === 'radio'"
              :title="langObj['s4-customQuestions'].modalQuestionType2"
              :subTitle="langObj['s4-customQuestions'].modalSingleQIntro"
              :description="
                langObj['s4-customQuestions'].modalSingleQDetailsLabel
              "
              :builderMode="
                questionsConfig.type === 'radio' &&
                questionsConfig.activeBuilder === 'radio'
                  ? true
                  : false
              "
              :editMode="questionsConfig.editMode"
              :langObj="langObj"
            >
              <MultiOptionsTypeBuilder
                v-if="
                  questionsConfig.type === 'radio' &&
                  questionsConfig.activeBuilder === 'radio'
                "
                @question-data="setQuestionData"
                @question-builde:close="closeQuestionBuilder"
                :editMode="questionsConfig.editMode"
                :questionInfo="customQuestionsList"
                :questionIndex="questionsConfig.editModeItemIndex"
                type="radio"
                sort="NONE"
                :langObj="langObj"
              ></MultiOptionsTypeBuilder>
              <SingleChoiceExample
                v-else
                :exampleQuestionText="
                  langObj['s4-customQuestions'].modalSingleQExampleLabel
                "
              ></SingleChoiceExample>
            </QuestionsTypePreview>

            <QuestionsTypePreview
              v-if="questionsConfig.type === 'checkbox'"
              :title="langObj['s4-customQuestions'].modalQuestionType3"
              :subTitle="langObj['s4-customQuestions'].modalMultiQIntro"
              :description="
                langObj['s4-customQuestions'].modalMultiQDetailsLabel
              "
              :builderMode="
                questionsConfig.type === 'checkbox' &&
                questionsConfig.activeBuilder === 'checkbox'
                  ? true
                  : false
              "
              :editMode="questionsConfig.editMode"
              :langObj="langObj"
            >
              <MultiOptionsTypeBuilder
                v-if="
                  questionsConfig.type === 'checkbox' &&
                  questionsConfig.activeBuilder === 'checkbox'
                "
                @question-data="setQuestionData"
                @question-builde:close="closeQuestionBuilder"
                :editMode="questionsConfig.editMode"
                :questionInfo="customQuestionsList"
                :questionIndex="questionsConfig.editModeItemIndex"
                type="checkbox"
                sort="SHUFFLE"
                :langObj="langObj"
              ></MultiOptionsTypeBuilder>
              <MultiChoiceExample
                v-else
                :exampleQuestionText="
                  langObj['s4-customQuestions'].modalMultiQExampleLabel
                "
              ></MultiChoiceExample>
            </QuestionsTypePreview>

            <QuestionsTypePreview
              v-if="questionsConfig.type === 'rank-dragdrop'"
              :title="langObj['s4-customQuestions'].modalQuestionType4"
              :subTitle="langObj['s4-customQuestions'].modalRankQIntro"
              :description="
                langObj['s4-customQuestions'].modalRankQDetailsLabel
              "
              :builderMode="
                questionsConfig.type === 'rank-dragdrop' &&
                questionsConfig.activeBuilder === 'rank-dragdrop'
                  ? true
                  : false
              "
              :editMode="questionsConfig.editMode"
              :langObj="langObj"
            >
              <MultiOptionsTypeBuilder
                v-if="
                  questionsConfig.type === 'rank-dragdrop' &&
                  questionsConfig.activeBuilder === 'rank-dragdrop'
                "
                @question-data="setQuestionData"
                @question-builde:close="closeQuestionBuilder"
                :editMode="questionsConfig.editMode"
                :questionInfo="customQuestionsList"
                :questionIndex="questionsConfig.editModeItemIndex"
                type="rank-dragdrop"
                sort="SHUFFLE"
                :langObj="langObj"
              ></MultiOptionsTypeBuilder>
              <RankExample
                v-else
                :exampleQuestionText="
                  langObj['s4-customQuestions'].modalRankQExampleLabel
                "
              ></RankExample>
            </QuestionsTypePreview>

            <QuestionsTypePreview
              v-if="questionsConfig.type === 'table-radio'"
              :title="langObj['s4-customQuestions'].modalQuestionType7"
              :subTitle="langObj['s4-customQuestions'].modalGridQIntro"
              :description="
                langObj['s4-customQuestions'].modalGridQDetailsLabel
              "
              :builderMode="
                questionsConfig.type === 'table-radio' &&
                questionsConfig.activeBuilder === 'table-radio'
                  ? true
                  : false
              "
              :editMode="questionsConfig.editMode"
              :langObj="langObj"
            >
              <GridTypeBuilder
                v-if="
                  questionsConfig.type === 'table-radio' &&
                  questionsConfig.activeBuilder === 'table-radio'
                "
                @question-data="setQuestionData"
                @question-builde:close="closeQuestionBuilder"
                :editMode="questionsConfig.editMode"
                :questionInfo="customQuestionsList"
                :questionIndex="questionsConfig.editModeItemIndex"
                :langObj="langObj"
              ></GridTypeBuilder>
              <GridExample
                v-else
                :langObj="langObj"
                :exampleQuestionText="
                  langObj['s4-customQuestions'].modalGridQExampleLabel
                "
                type="table-radio"
              ></GridExample>
            </QuestionsTypePreview>

            <QuestionsTypePreview
              v-if="questionsConfig.type === 'table-checkbox'"
              :title="langObj['s4-customQuestions'].modalQuestionType5"
              :subTitle="langObj['s4-customQuestions'].modalGridCheckboxQIntro"
              :description="
                langObj['s4-customQuestions'].modalGridCheckboxQDetailsLabel
              "
              :builderMode="
                questionsConfig.type === 'table-checkbox' &&
                questionsConfig.activeBuilder === 'table-checkbox'
                  ? true
                  : false
              "
              :editMode="questionsConfig.editMode"
              :langObj="langObj"
            >
              <GridTypeBuilder
                v-if="
                  questionsConfig.type === 'table-checkbox' &&
                  questionsConfig.activeBuilder === 'table-checkbox'
                "
                @question-data="setQuestionData"
                @question-builde:close="closeQuestionBuilder"
                :editMode="questionsConfig.editMode"
                :questionInfo="customQuestionsList"
                :questionIndex="questionsConfig.editModeItemIndex"
                :langObj="langObj"
                type="table-checkbox"
              ></GridTypeBuilder>
              <GridExample
                v-else
                :langObj="langObj"
                :exampleQuestionText="
                  langObj['s4-customQuestions'].modalGridCheckboxQExampleLabel
                "
              ></GridExample>
            </QuestionsTypePreview>

            <div
              class="flex justify-center"
              v-if="questionsConfig.activeBuilder === null"
            >
              <a-button
                type="primary"
                @click="setBuilderType"
                v-html="langObj['s4-customQuestions'].modalButton5"
              >
              </a-button>
            </div>
          </template>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import ProgressBar from "@/components/videoAds/ProgressBar.vue";
import StepNaviBar from "@/components/videoAds/StepNaviBar1.vue";
import QuestionsTypePreview from "@/components/questions/QuestionsTypePreview.vue";
import TextTypeExample from "@/components/questions/TextTypeExample.vue";
import TextTypeBuilder from "@/components/questions/TextTypeBuilder.vue";
import SingleChoiceExample from "@/components/questions/SingleChoiceExample.vue";
import MultiChoiceExample from "@/components/questions/MultiChoiceExample.vue";
import MultiOptionsTypeBuilder from "@/components/questions/MultiOptionsTypeBuilder.vue";
import GridTypeBuilder from "@/components/questions/GridTypeBuilder.vue";
import RankExample from "@/components/questions/RankExample.vue";
import GridExample from "@/components/questions/GridExample.vue";
import QuestionsListPreview from "@/components/questions/QuestionsListPreview.vue";
import Loader from "@/components/general/Loader.vue";
import surveyPriceMixin from "@/mixins/surveyPriceCalculation.js";
import * as jsUtils from "@/utils/jsUtils.js";
import * as wsUtils from "@/utils/wsUtils.js";
import IconBase from "@/components/general/IconBase.vue";
import IconQuestion from "@/components/icons/IconQuestion.vue";
import IconSearchThin from "@/components/icons/IconSearchThin.vue";
import IconNoResults from "@/components/icons/IconNoResults.vue";

export default {
  name: "CustomQuestions",
  components: {
    ProgressBar,
    StepNaviBar,
    QuestionsTypePreview,
    TextTypeExample,
    SingleChoiceExample,
    MultiChoiceExample,
    RankExample,
    GridExample,
    TextTypeBuilder,
    MultiOptionsTypeBuilder,
    GridTypeBuilder,
    QuestionsListPreview,
    Loader,
    IconBase,
    IconQuestion,
    IconSearchThin,
    IconNoResults,
  },
  mixins: [surveyPriceMixin],
  data() {
    return {
      surveyID: null,
      user: null,
      client: null,
      langObj: null,
      questionsConfig: {
        type: "text",
        radioBtnTypesStyle: {
          display: "block",
          height: "30px",
          lineHeight: "30px",
        },
        activeBuilder: "text",
        previewQuestionsList: false,
        editModeItemIndex: null,
        editMode: false,
        activeMode: "builder",
        loadingNewQuestions: false,
      },
      addCustomQuestionModal: false,
      questionsData: {
        questionsInstructions: "Testing1",
        surveyID: null,
        CustomQuestions: [],
      },
      customQuestionsList: null,
      loading: false,
      tempHide: true,
      surveyType: null,
      savedQuestions: [],
      loadingSavedQuestions: false,
      searchText: "",
      categoryFilter: undefined,
      searchActive: false,
      insertCustomQuestionsLoading: false,
    };
  },
  computed: {
    questionsModalTitle() {
      let title = "Examples";
      if (this.questionsConfig.previewQuestionsList) {
        title = "My questions list";
      } else if (
        this.questionsConfig.activeBuilder !== null &&
        !this.questionsConfig.editMode
      ) {
        title = "Create a question";
      } else if (
        this.questionsConfig.activeBuilder !== null &&
        this.questionsConfig.editMode
      ) {
        title = "Edit a question";
      }
      return title;
    },
    dlbStatus() {
      return this.surveyType === "packtestextend";
    },
    uniqueQuestionsCategories() {
      if (this.savedQuestions.length === 0) {
        return;
      }

      const categories = [
        ...new Set(
          this.savedQuestions.map((question) => question.questionCategory)
        ),
      ];

      return [
        ...categories.map((category) => ({
          value: category,
          label: category,
        })),
      ];
    },
    filteredQuestions() {
      if (
        this.searchText.length < 3 &&
        (!this.categoryFilter || this.categoryFilter.length === 0)
      ) {
        return this.savedQuestions;
      }

      return this.savedQuestions.filter((question) => {
        const matchesText = question.questionText
          .toLowerCase()
          .includes(this.searchText.toLowerCase());

        const matchesCategory =
          !this.categoryFilter ||
          this.categoryFilter.length === 0 ||
          this.categoryFilter.includes(question.questionCategory);
        return matchesText && matchesCategory;
      });
    },
  },
  watch: {
    "questionsConfig.type": {
      handler: function () {
        if (!this.questionsConfig.editMode) {
          this.questionsConfig.activeBuilder = null;
        }
        if (this.questionsConfig.activeMode === "builder") {
          this.questionsConfig.activeBuilder = this.questionsConfig.type;
        }
      },
      deep: true,
    },
  },
  methods: {
    nextStep() {
      let routeName = this.$route.path.includes("pack-test-custom")
        ? "pack-test-launch-custom"
        : "pack-test-launch";
      this.$router.push({
        name: routeName,
        params: { id: this.surveyID },
      });
      this.setSurveySummaryData();
    },
    prevStep() {
      let routeName = this.$route.path.includes("pack-test-custom")
        ? "communication-objective-custom"
        : "communication-objective";
      this.$router.push({
        name: routeName,
        params: { id: this.surveyID },
      });
    },
    closeQuestionBuilder(e) {
      this.addCustomQuestionModal = false;
      let _this = this;
      if (e !== "cancel") {
        this.getCustomQuestions();
      }

      setTimeout(function () {
        _this.questionsConfig.editMode = false;
        _this.questionsConfig.activeBuilder = "text";
        _this.questionsConfig.type = "text";
        _this.questionsConfig.activeMode = "builder";
      }, 500);
    },
    setQuestionData(data, index) {
      let questions;
      if (index !== null && index !== undefined) {
        this.customQuestionsList.CustomQuestions[index] = data;
        this.questionsConfig.editModeItemIndex = null;
        this.questionsConfig.editMode = false;
        questions = this.customQuestionsList;
      } else {
        this.questionsData.CustomQuestions.push(data);
        questions = this.questionsData;
      }
      this.questionsConfig.loadingNewQuestions = true;
      this.insertCustomQuestions(questions, true);
    },
    setBuilderType() {
      this.questionsConfig.activeBuilder = this.questionsConfig.type;
      this.questionsConfig.activeMode = "builder";
    },
    deleteCustomQuestions(index) {
      this.customQuestionsList.CustomQuestions.splice(index, 1);
      this.insertCustomQuestions(this.customQuestionsList);
    },

    insertCustomQuestions(data, showNotification, closeModal) {
      this.insertCustomQuestionsLoading = true;
      data.userId = this.user.EncrypteduserID;
      const insertCustomQuestionPromise = new Promise((resolve) => {
        wsUtils.InsertCustomQuestion(data, resolve);
      });

      if (!closeModal) {
        this.addCustomQuestionModal = false;
      }

      this.questionsData.CustomQuestions = [];
      this.questionsConfig.activeBuilder = "text";
      this.questionsConfig.type = "text";
      insertCustomQuestionPromise.then(() => {
        this.questionsConfig.loadingNewQuestions = false;
        this.getCustomQuestions();
        this.setSurveySummaryData();
        if (showNotification) {
          this.$notification["success"]({
            message: "Success!",
            description: "Your changes have been saved.",
            placement: "bottomLeft",
            duration: 4,
          });
        }
        this.insertCustomQuestionsLoading = false;
      });
    },
    setSurveySummaryData() {
      let surveySummary = {
        customQuestions: this.customQuestionsList.CustomQuestions.map(
          (item) => {
            return item.questionText;
          }
        ),
      };
      let currentSummaryData = jsUtils.getSurveySummaryData();
      jsUtils.setSurveySummaryData({
        ...currentSummaryData,
        ...surveySummary,
      });
    },
    getCustomQuestions() {
      const getCustomQuestionsPromise = new Promise((resolve) => {
        wsUtils.GetCustomQuestion(this.surveyID, resolve);
      });
      getCustomQuestionsPromise.then((data) => {
        this.customQuestionsList = data;
        this.questionsData.questionsInstructions = data.questionsInstructions;
        this.loading = false;
        let questionsType = data.CustomQuestions.map((item) => {
          return { QuestionType: item.questionType };
        });

        let surveyPriceSetData = {
          Question: questionsType,
        };
        let currentPackTestPriceData = jsUtils.getPackTestPrice();

        jsUtils.setPackTestPrice({
          ...currentPackTestPriceData,
          ...surveyPriceSetData,
        });
        this.getPackTestTotalPrice();
      });
    },
    editQuestion(data) {
      this.addCustomQuestionModal = true;
      this.questionsConfig.editMode = true;
      this.questionsConfig.editModeItemIndex = data.index;
      this.questionsConfig.previewQuestionsList = false;
      this.questionsConfig.type = data.questionType;
      this.questionsConfig.activeBuilder = data.questionType;
    },
    saveInLibrary(value, index) {
      if (!value) {
        this.deleteSavedQuestions(
          index,
          this.customQuestionsList.CustomQuestions[index].SurveyCustomQuestionID
        );
      }
      this.customQuestionsList.CustomQuestions[index].saveInLibrary = value;
      this.insertCustomQuestions(this.customQuestionsList);
    },
    getSavedQuestions() {
      this.loadingSavedQuestions = true;
      const model = {
        wsName: "GetSavedCustomQuestions",
        params: null,
      };

      const getQuota = new Promise((resolve) => {
        wsUtils.ApiGet(model, resolve);
      });

      getQuota.then((data) => {
        this.savedQuestions = data.CustomQuestions;
        this.loadingSavedQuestions = false;
      });
    },
    addSavedQuestion(data) {
      data.saveInLibrary = false;
      data.setAsScreener = false;
      data.SurveyCustomQuestionID = 0;
      let question = {
        questionsInstructions: "",
        CustomQuestions: [data],
        surveyID: this.surveyID,
      };
      // delete data.itemType;
      // this.customQuestionsList.CustomQuestions.push(data);
      this.insertCustomQuestions(question, true, true);
    },
    deleteSavedQuestions(index, id) {
      const model = {
        wsName: "DeleteSavedCustomQuestions",
        params: {
          SurveyCustomQuestionID:
            id || this.savedQuestions[index].SurveyCustomQuestionID,
        },
      };

      const deleteQuestion = new Promise((resolve) => {
        wsUtils.ApiGet(model, resolve);
      });

      deleteQuestion.then((data) => {
        if (data.Success) {
          this.getSavedQuestions();

          if (!id) {
            this.$notification["success"]({
              message: "Success!",
              description: "Your question has been deleted.",
              placement: "bottomLeft",
              duration: 4,
            });
            this.getCustomQuestions();
          }
        } else {
          this.$notification["error"]({
            message: "Error!",
            description:
              "Delete question failed. Please try again or contact our support.",
            placement: "bottomLeft",
            duration: 4,
          });
        }
      });
    },
  },
  activated() {
    this.surveyType = jsUtils.readCookie("survey-type");
    this.loading = true;
    this.surveyID = this.$route.params.id;
    this.user = jsUtils.getUserInfo();
    this.client = jsUtils.getCurClient();
    this.surveyID = this.$route.params.id;
    this.questionsData.surveyID = this.surveyID;
    this.customQuestionsList = null;
    this.getCustomQuestions();
  },

  created() {
    this.loading = true;
    this.langObj = jsUtils.getLangObj(
      "videoAuthorLang",
      "/tooltip/videoAuthor.json"
    );
  },
};
</script>

<style lang="scss" scoped>
.questions {
  text-align: left;
  .authorBtnSection {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 9999;
  }
  &__box {
    margin-bottom: 30px;
    ::v-deep .questions-list__item {
      &:last-child {
        margin-bottom: 0;
      }
    }

    label {
      font-size: 14px;
      margin-bottom: 5px;
      color: #2c3e50;
      display: block;
      font-weight: 500;
    }
  }
  &__wrapper {
    height: calc(100vh - 168px);
    overflow: auto;
    padding: 0 20px;
  }
  &__inner {
    max-width: 710px;
    margin: 0 auto;
    padding: 50px 0 110px;
    min-height: 100%;
    h2 {
      color: #1c1c1c;
      font-size: 20px;
      line-height: normal;
      font-weight: 500;
      letter-spacing: -0.4px;
      margin-bottom: 28px;
    }
  }
  &__intro {
    margin-bottom: 40px;
  }
}
.choose-questions-nav {
  margin-bottom: 20px;

  .ant-btn {
    min-width: 100px;
    margin-right: -1px;
    border-radius: 0;
  }
}
</style>

<style lang="scss">
.ant-input-group-addon {
  background-color: #787878;
  transition: background-color 0.3s;

  .anticon {
    color: white;
    font-size: 1.2em;
  }

  &:hover {
    background-color: #555;
  }
}

.ant-modal {
  &--custom-questions {
    backdrop-filter: none !important;
    .ant-modal-body {
      display: block;
      max-height: inherit;
    }
  }
}
</style>
