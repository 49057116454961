<template>
  <div class="authoring-wrapper">
    <div class="flex h-full">
      <ProgressBar
        :stepIndex="7"
        :surveyID="surveyID"
        stepsType="pack"
        :conciergeAssistance="true"
      />
      <section class="wrapper">
        <a-breadcrumb class="breadcrum breadcrum--authoring">
          <template v-if="user !== null && user.SkinIdentifier !== 'pg'">
            <a-breadcrumb-item
              ><a href="/">{{ client.ClientName }}</a></a-breadcrumb-item
            >
            <a-breadcrumb-item>Study Results</a-breadcrumb-item>
          </template>
        </a-breadcrumb>
        <div class="wrapper__inner">
          <div class="page-title">
            <icon-base
              :width="32"
              :height="33"
              :viewBox1="32"
              :viewBox2="33"
              iconName="results"
              iconColor="transparent"
              ><icon-results
            /></icon-base>
            <div class="page-title__text">
              <h1>Study Results</h1>
            </div>
          </div>
          <div class="box-wrapper">
            <div class="results-title">
              <h2>Oreo</h2>
              <img src="/images/oreo.jpg" width="94" alt="" />
              <div class="flex ml-auto">
                <span class="results-title__legend"
                  >Significant Increase
                  <icon-base
                    :width="20"
                    :height="12"
                    :viewBox1="20"
                    :viewBox2="12"
                    iconName="increase"
                    iconColor="transparent"
                    ><icon-increase /></icon-base
                ></span>
                <span class="results-title__legend"
                  >Significant Decrease
                  <icon-base
                    :width="20"
                    :height="12"
                    :viewBox1="20"
                    :viewBox2="12"
                    iconName="decrease"
                    iconColor="transparent"
                    ><icon-decrease /></icon-base
                ></span>
              </div>
            </div>
            <table class="table-study-results mb-20">
              <tr>
                <th></th>
                <td>All See, Select, Buy Numbers for Total Lysol Brand</td>
                <td>Current</td>
                <td>Redesign</td>
              </tr>
              <tr>
                <th>
                  <icon-base
                    :width="17"
                    :height="16"
                    :viewBox1="17"
                    :viewBox2="12"
                    iconName="see"
                    iconColor="transparent"
                    ><icon-see /></icon-base
                  >SEE
                </th>
                <td>% of Attention on Shelf</td>
                <td>20</td>
                <td class="increase">
                  <div class="flex justify-between items-center">
                    27<icon-base
                      :width="20"
                      :height="12"
                      :viewBox1="20"
                      :viewBox2="12"
                      iconName="increase"
                      iconColor="transparent"
                      ><icon-increase
                    /></icon-base>
                  </div>
                </td>
              </tr>
              <tr>
                <th rowspan="2">
                  <icon-base
                    :width="17"
                    :height="20"
                    :viewBox1="17"
                    :viewBox2="20"
                    iconName="select"
                    iconColor="transparent"
                    ><icon-select /></icon-base
                  >SELECT
                </th>
                <td>% of Pick Ups</td>
                <td>15</td>
                <td class="increase">
                  <div class="flex justify-between items-center">
                    16<icon-base
                      :width="20"
                      :height="12"
                      :viewBox1="20"
                      :viewBox2="12"
                      iconName="increase"
                      iconColor="transparent"
                      ><icon-increase
                    /></icon-base>
                  </div>
                </td>
              </tr>
              <tr>
                <td>% of Findability</td>
                <td>58</td>
                <td class="decrease">
                  <div class="flex justify-between items-center">
                    42
                    <icon-base
                      :width="20"
                      :height="12"
                      :viewBox1="20"
                      :viewBox2="12"
                      iconName="decrease"
                      iconColor="transparent"
                      ><icon-decrease
                    /></icon-base>
                  </div>
                </td>
              </tr>
              <tr>
                <th rowspan="2">
                  <icon-base
                    :width="18"
                    :height="16"
                    :viewBox1="18"
                    :viewBox2="16"
                    iconName="buy"
                    iconColor="transparent"
                    ><icon-buy /></icon-base
                  >BUY
                </th>
                <td>% of Purchase</td>
                <td>34</td>
                <td class="increase">
                  <div class="flex justify-between items-center">
                    42<icon-base
                      :width="20"
                      :height="12"
                      :viewBox1="20"
                      :viewBox2="12"
                      iconName="increase"
                      iconColor="transparent"
                      ><icon-increase
                    /></icon-base>
                  </div>
                </td>
              </tr>
              <tr>
                <td>$ Spent</td>
                <td>$1023</td>
                <td class="increase">
                  <div class="flex justify-between items-center">
                    $1268<icon-base
                      :width="20"
                      :height="12"
                      :viewBox1="20"
                      :viewBox2="12"
                      iconName="increase"
                      iconColor="transparent"
                      ><icon-increase
                    /></icon-base>
                  </div>
                </td>
              </tr>
              <tr>
                <th rowspan="3">
                  <icon-base
                    :width="17"
                    :height="18"
                    :viewBox1="17"
                    :viewBox2="18"
                    iconName="think"
                    iconColor="transparent"
                    ><icon-think /></icon-base
                  >THINK
                </th>
                <td>Gives me peace of mind</td>
                <td>51</td>
                <td class="increase">
                  <div class="flex justify-between items-center">
                    61<icon-base
                      :width="20"
                      :height="12"
                      :viewBox1="20"
                      :viewBox2="12"
                      iconName="increase"
                      iconColor="transparent"
                      ><icon-increase
                    /></icon-base>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Is more effective than other cleaning products</td>
                <td>62</td>
                <td class="equal">
                  <div class="flex justify-between items-center">
                    62<icon-base
                      :width="18"
                      :height="18"
                      :viewBox1="18"
                      :viewBox2="18"
                      iconName="equal"
                      iconColor="transparent"
                      ><icon-equal
                    /></icon-base>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Is safe for my family</td>
                <td>39</td>
                <td class="increase">
                  <div class="flex justify-between items-center">
                    48<icon-base
                      :width="20"
                      :height="12"
                      :viewBox1="20"
                      :viewBox2="12"
                      iconName="increase"
                      iconColor="transparent"
                      ><icon-increase
                    /></icon-base>
                  </div>
                </td>
              </tr>
            </table>
            <div class="text-center">
              <img src="/images/results-chart.jpg" width="390" alt="Results" />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import ProgressBar from "@/components/videoAds/ProgressBar.vue";
import * as jsUtils from "@/utils/jsUtils.js";
import surveyPriceMixin from "@/mixins/surveyPriceCalculation.js";
import IconBase from "@/components/general/IconBase.vue";
import IconResults from "@/components/icons/IconChartResults.vue";
import IconSee from "@/components/icons/IconSee.vue";
import IconThink from "@/components/icons/IconThink.vue";
import IconIncrease from "@/components/icons/IconIncrease.vue";
import IconDecrease from "@/components/icons/IconDecrease.vue";
import IconEqual from "@/components/icons/IconEqual.vue";
import IconBuy from "@/components/icons/IconBuy.vue";
import IconSelect from "@/components/icons/IconSelect.vue";

export default {
  name: "StudyResults",
  components: {
    ProgressBar,
    IconBase,
    IconResults,
    IconSee,
    IconThink,
    IconIncrease,
    IconDecrease,
    IconEqual,
    IconBuy,
    IconSelect,
  },
  mixins: [surveyPriceMixin],
  props: {
    SurveyTypeId: Number,
  },
  data() {
    return {
      client: null,
      user: null,
    };
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {
    this.client = jsUtils.getCurClient();
    this.user = jsUtils.getUserInfo();
  },
  activated() {
    this.surveyID = this.$route.params.id;
  },
};
</script>

<style lang="scss" scoped>
.results-title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  h2 {
    font-size: 36px;
    font-weight: 600;
    margin-right: 25px;
    margin-bottom: 0;
  }
  &__legend {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    margin-left: 20px;
    svg {
      margin-left: 10px;
    }
  }
}
.table-study-results {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  tr {
    th {
      font-size: 15px;
      font-weight: 600;
      border: #b9b9b9 1px solid;
      border-top: 0;
      padding: 9px;
      text-align: center;
      svg {
        margin-right: 10px;
      }
    }
    td {
      font-size: 14px;
      padding: 9px;
      border-bottom: #b9b9b9 1px solid;
      border-right: #b9b9b9 1px solid;
      font-weight: 500;
      color: rgba(28, 28, 28, 1);
      &.increase {
        background: #cbf2dc;
      }
      &.decrease {
        background: #ffccd9;
      }
      &.equal {
        background: #fdcda8;
      }
    }
    &:first-child {
      th {
        border: 0;
      }
      td {
        background: rgba(255, 3, 3, 0.06);
        font-weight: 600;
        padding: 10px;
        font-size: 16px;
        border-top: #b9b9b9 1px solid;
        &:nth-child(2) {
          border-top-left-radius: 4px;
          border-left: #b9b9b9 1px solid;
        }
        &:last-child {
          border-top-right-radius: 4px;
        }
      }
    }
    &:nth-child(2) {
      th {
        border-top-left-radius: 4px;
        border-top: #b9b9b9 1px solid;
      }
    }
    &:nth-child(7) {
      th {
        border-bottom-left-radius: 4px;
      }
    }
    &:nth-child(9) {
      td {
        &:nth-child(3) {
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
}
</style>
